
<template>
  <div class="wrap">
    <div class="weixinLogin">
      <h2>微信登录</h2>
      <img :src="src" alt="扫描二维码微信登录" />
      <p>请使用微信扫码登录平台</p>
      <div class="tips">
        <span>扫码登录平台，表示你同意</span>
        <router-link to="/protocol/userServe/" target="_blank"
          >用户服务协议</router-link
        >
        和
        <router-link to="/protocol/agreement/" target="_blank"
          >隐私政策</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      key: "",
      timeInterval: "",
      _token: "",
      src: "",
    };
  },
  methods: {
    loginStart() {
      this.$axios.get("/api/app-jycy-wechat/wx/loginStart").then((res) => {
        if (res.data.success) {
          this.key = res.data.data.code;
          this.src = res.data.data.url;
          this.timeInterval = setInterval(() => {
            this.loginStatusCheck();
          }, 5000);
        }
      });
    },
    loginStatusCheck() {
      this.$axios
        .get("/api/app-jycy-wechat/wx/loginStatusCheck?key=" + this.key)
        .then((res) => {
          if (res.data.success) {
            window.clearInterval(this.timeInterval);
            this._token = res.data.data.user.token;
            //存储access_token
            this.$store.commit("SET_TOKEN", this._token);
            //存储refresh_token
            this.$store.commit("SET_REFRESH_TOKEN", this._token);
            this.$message({
              message: "登录成功",
              type: "success",
            });
            this.refresh();
          }
        });
    },
    refresh() {
      this.$api.loginRefreshApi().then((res) => {
        let user = res.data.data;
        this.$store.commit("SET_USER_INFO", user);
        this.$router.push("/");
      });
    },
  },
  created() {
    this.loginStart();
  },
};
</script>
<style lang="less" scoped>
.wrap {
  background-color: #f2f2f2;
  padding-top: 100px;
  .weixinLogin {
    width: 380px;
    margin: 0 auto;
    padding: 55px 0 50px;
    background: #fff;
    text-align: center;
    h2 {
      margin-bottom: 6px;
      font-size: 24px;
      font-weight: 300;
      line-height: 36px;
      color: #808083;
    }
    img {
      width: 200px;
      height: 200px;
    }
    p {
      margin-top: 9px;
      font-size: 14px;
      line-height: 20px;
    }
    .tips {
      margin: 10px 0 15px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      line-height: 14px;
    }
    a {
      color: #00924b;
    }
  }
}
</style>